import styled, { css } from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

interface RelatedLinkImageContainerProps {
  image: string | null | undefined
}

export const RelatedLinkBoxContainer = styled.div`
  max-width: 100%;
  padding: 32px 45px 39px;
  background-color: ${rspTheme.palette.primary.light};

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 30px 40px 35px;
  }

  > a {
    font-size: 24px;
    color: ${rspTheme.palette.primary.contrastText};
    text-decoration: underline solid ${rspTheme.palette.primary.contrastText};
    line-height: 28px;
    font-weight: 300;

    @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
      font-size: 22px;
      line-height: inherit;
    }
  }
`
export const RelatedLinkImageContainer = styled.div`
  position: absolute;
  height: 100%;

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    opacity: 0.5;
  }
`

export const RelatedLinkImage = styled.div<RelatedLinkImageContainerProps>`
  height: 100%;
  border-radius: 3px;

  ${(props) =>
    props.image &&
    css`
      background-image: url(${props.image});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    `}
`

export const TextContainer = styled.div`
  padding: 16px 21px;
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 2;
  border-radius: 3px 0 0 3px;
`

export const RelatedLinkButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  z-index: 2;
  border-radius: 0 3px 3px 0;

  a {
    button {
      box-shadow: 0 0 24px rgba(0, 0, 0, 0.25);
    }
  }

  a:hover {
    button {
      box-shadow: 0 0 24px rgba(0, 0, 0, 0.3);
      opacity: 0.98;
    }
  }
`

export const Title = styled.h3`
  color: #0f034a;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 8px;
`

export const Text = styled.p`
  font-weight: 500;
  line-height: 24px;
`

export const RelatedLinkContainer = styled.div<{ mode: string | null }>`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  border: none;
  box-shadow: 1px 1px 26px 3px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  position: relative;
  height: 140px;
  max-width: 800px;
  margin: 0 auto;
  background: white;

  a {
    align-self: center;

    button {
      border: none;
      border-radius: 8px;
      background: ${rspTheme.palette.primary.light};
      color: #ffffff;
      line-height: 28px;
      letter-spacing: 1.3px;
      text-transform: uppercase;
      padding: 4px 30px;
      cursor: pointer;
      font-weight: 600;
    }
  }

  ${(props) => {
    switch (props.mode) {
      case 'rectangular':
        return css`
          a button {
            background: #925b84;
          }

          ${TextContainer} {
            background: linear-gradient(125deg, white 45%, transparent 80%);
          }

          ${RelatedLinkImageContainer} {
            right: 15%;
            width: 70%;
          }

          ${RelatedLinkButtonContainer} {
            padding-right: 56px;
            background: linear-gradient(
              to left,
              ${rspTheme.palette.primary.dark} 15%,
              ${rspTheme.palette.primary.dark}ee 30%,
              transparent 80%
            );
          }
        `
      case 'triangular':
        return css`
          a button {
            background: #75bac2;
          }

          ${Title}, ${Text} {
            width: 90%;
          }

          ${RelatedLinkImageContainer} {
            right: 0;
            width: 55%;

            ${RelatedLinkImage} {
              clip-path: polygon(5% 100%, 0% 50%, 5% 0%, 100% 0%, 100% 100%);
              position: relative;
              z-index: 1;
            }

            &::before,
            &::after {
              content: '';
              position: absolute;
              background-color: #925b84;
              left: -2px;
              height: 43%;
              width: 25px;
            }

            &::before {
              bottom: 49.75%;
              border-radius: 8px 0 0;
              transform: skewX(-19deg);
            }

            &::after {
              top: 49.75%;
              border-radius: 0 0 0 8px;
              transform: skewX(19deg);
            }
          }

          ${RelatedLinkButtonContainer} {
            padding-right: 40px;
          }
        `
      default:
        return ''
    }
  }}

  @media screen and (max-width: ${rspTheme.breakpoints.values.md}px) {
    flex-direction: column;
    height: unset;

    ${TextContainer} {
      background: linear-gradient(125deg, white 45%, transparent 80%);

      ${Title}, ${Text} {
        display: initial;
        width: 90%;
      }
    }

    ${RelatedLinkImageContainer} {
      right: 0;
      width: 100%;

      ${RelatedLinkImage} {
        clip-path: none;
      }

      &::before,
      &::after {
        display: none;
      }
    }

    ${RelatedLinkButtonContainer} {
      background: none;
      padding: 20px;
      justify-content: flex-start;
    }
  }
`
