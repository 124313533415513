import React from 'react'

import { CtaBlockWrapper, CtaBlockImageContainer, CTABody } from './styles'

const CtaBlock = ({
  title,
  text,
  button_label,
  url,
  image,
  id,
}: Queries.CtaBlockContainerFragment) => (
  <CtaBlockWrapper id={id}>
    <CtaBlockImageContainer image={image}>
      <CTABody>
        <h3>{title}</h3>
        <p>{text}</p>
        {url ? (
          <a href={url} rel="noopener noreferrer" target="_blank">
            <button type="button">{button_label}</button>
          </a>
        ) : null}
      </CTABody>
    </CtaBlockImageContainer>
  </CtaBlockWrapper>
)

export default CtaBlock
