import styled from 'styled-components'

import linkTriangle from '../../images/linkTriangle.svg'
import { rspTheme } from '../../styles/rspTheme'

export const FaqAccordionContainer = styled.div`
  max-width: 100%;
  padding: 39px 0 58px;
`

export const Title = styled.div`
  font-size: 34px;
  color: ${rspTheme.palette.primary.main};
  line-height: 55px;
  font-weight: 300;
  margin-bottom: 19px;

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    font-size: 30px;
    line-height: inherit;
  }
`

export const AccordionItem = styled.div`
  border-top: 1px solid ${rspTheme.palette.primary.main};
  &:last-child {
    border-bottom: 1px solid ${rspTheme.palette.primary.main};
  }
`

export const AccordionTitle = styled.div`
  font-size: 24px;
  color: ${rspTheme.palette.primary.main};
  line-height: 30px;
  padding: 26px 34px 30px 0;
  font-weight: 300;
  cursor: pointer;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: 10px;
    top: calc(50% - 8px);
    width: 16px;
    height: 16px;
    background-image: url(${linkTriangle});
    background-repeat: no-repeat;
    background-size: 8px;
    transform: rotateZ(90deg);
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    font-size: 22px;
  }
`

export const AccordionContent = styled.div`
  color: ${rspTheme.palette.primary.main};
  padding: 0px 20px 30px;
  font-weight: 300;
  font-size: 18px;
`
