import { graphql, HeadProps, PageProps } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import CtaBlock from '../components/CtaBlock/CtaBlockContainer'
import { FaqAccordion } from '../components/FaqAccordion/FaqAccordion'
import Layout from '../components/Layout'
import { Hero } from '../components/PageHero/Hero'
import { RelatedArticles } from '../components/RelatedArticles/RelatedArticles'
import { RelatedLinkBox } from '../components/RelatedLinkBox/RelatedLinkBox'
import { RichText } from '../components/RichText/RichText'
import { SchemaMarkup } from '../components/SchemaMarkup/SchemaMarkup'
import { SchemaMarkupBreadcrumb } from '../components/SchemaMarkup/SchemaMarkupBreadcrumb'
import { SchemaMarkupFAQ } from '../components/SchemaMarkup/SchemaMarkupFAQ'
import { TableOfContents } from '../components/TableOfContents/TableOfContents'
import { booleanFilter, sanitizeUrlAnchor } from '../utils/helpers'

const SectionSeparator = styled.div<{ index: number; sectionColor?: string }>`
  border: none transparent ${({ index }) => index}px;

  .relatedlinkcontainer + & {
    height: 55px;
  }

  & ~ .contentitem {
    background-color: ${({ sectionColor }) => sectionColor ?? 'transparent'};
  }
`

const SECTION_COLORS = ['#f3f1f5', '#f9f4f5', '#ffffff'] as const

const Page = ({
  data,
  pageContext,
}: PageProps<Queries.PageQuery, { slug: string }>) => {
  const pageData = data?.contentstackPage
  const breadcrumbLinks = [{ text: pageData?.title || '' }]

  const extractTitle = (component) => {
    const matches = component?.content?.match(/<(h1|h2)[^>]*>(.*?)<\/\1>/i)

    return matches && matches.length >= 3
      ? sanitizeUrlAnchor(matches[2])
      : component.id
  }

  const sections =
    pageData?.components
      ?.filter(booleanFilter)
      .filter(
        (component) =>
          component &&
          component.__typename === 'Contentstack_component__section_separator',
      )
      .map((component) => component?.id) ?? []

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      description={pageData?.metadata?.description}
      image={pageData?.metadata?.image}
      locale={pageData?.publish_details?.locale}
      slug={pageContext.slug}
      title={pageData?.metadata?.page_title || pageData?.title}
    >
      <Hero
        color={pageData?.hero_text_color}
        image={pageData?.hero_image?.url}
        subtext={pageData?.subhero_text}
        text={pageData?.hero_text}
      />
      {pageData?.components ? (
        <TableOfContents components={pageData.components} />
      ) : null}

      {pageData?.components?.map((component, index) => {
        const contentHeading = extractTitle(component)
        const faqHeading =
          component && 'title' in component
            ? sanitizeUrlAnchor(component?.title)
            : ''

        switch (component?.__typename) {
          case 'Contentstack_component__related_articles':
            return (
              <RelatedArticles
                id={component.id}
                key={component.id}
                links={component.links}
                title={component.title}
              />
            )
          case 'Contentstack_component__related_link_box':
            return (
              <div
                className="contentitem relatedlinkcontainer"
                key={component.id}
              >
                <RelatedLinkBox
                  button_label={component.button_label}
                  image={component.image}
                  style={component.style}
                  text={component.text}
                  title={component.title}
                  url={component.url}
                />
              </div>
            )
          case 'Contentstack_component__faq_accordion':
            return (
              <div className="contentitem" id={faqHeading} key={component.id}>
                <FaqAccordion
                  questions={component.questions}
                  title={component.title}
                />
              </div>
            )
          case 'Contentstack_component__cta_block':
            return (
              <div className="contentitem" key={component.id}>
                <CtaBlock
                  button_label={component.button_label}
                  id={component.id}
                  image={component.image}
                  style={component.style}
                  text={component.text}
                  title={component.title}
                  url={component.url}
                />
              </div>
            )
          case 'Contentstack_component__rich_text':
            return (
              <div
                className="contentitem"
                id={contentHeading}
                key={component.id}
              >
                <RichText content={component.content} id={component.id} />
              </div>
            )
          case 'Contentstack_component__section_separator':
            return (
              <SectionSeparator
                className="contentitem"
                index={index}
                key={component.id}
                sectionColor={
                  SECTION_COLORS[
                    sections.findIndex((id) => id === component.id) %
                      SECTION_COLORS.length
                  ]
                }
              />
            )
          default: {
            return null
          }
        }
      })}
    </Layout>
  )
}

export const Head = ({ data }: HeadProps<Queries.PageQuery>) => {
  const pageData = data?.contentstackPage

  const faqSection = pageData?.components?.find(
    (component) =>
      component?.__typename === 'Contentstack_component__faq_accordion',
  )

  return (
    <>
      {faqSection &&
      faqSection.__typename === 'Contentstack_component__faq_accordion' ? (
        <SchemaMarkupFAQ questions={faqSection.questions} />
      ) : null}
      <SchemaMarkup data={data.contentstackPage?.schema_markup} />
      <SchemaMarkupBreadcrumb pageTitle={pageData?.title} />
    </>
  )
}

export default Page

export const data = graphql`
  query Page($pageId: String) {
    contentstackPage(id: { eq: $pageId }) {
      id
      locale
      publish_details {
        locale
      }
      metadata {
        page_title
        description
        image {
          description
          url
        }
      }
      hero_image {
        url
      }
      hero_text
      hero_text_color
      subhero_text
      title
      url
      components {
        __typename
        ... on Contentstack_component__section_separator {
          id
        }
        ... on Contentstack_component__rich_text {
          ...RichText
        }
        ... on Contentstack_component__related_link_box {
          ...RelatedLinkBox
        }
        ... on Contentstack_component__related_articles {
          ...RelatedArticles
        }
        ... on Contentstack_component__faq_accordion {
          ...FaqAccordion
        }
        ... on Contentstack_component__cta_block {
          id
          image {
            url
          }
          text
          style
          title
          button_label
          url
        }
      }
      schema_markup {
        ...SchemaMarkup
      }
    }
  }

  fragment RelatedLinkBox on Contentstack_component__related_link_box {
    id
    title
    text
    button_label
    url
    style
    image {
      url
    }
  }

  fragment FaqAccordion on Contentstack_component__faq_accordion {
    id
    questions {
      answer
      title
    }
    title
  }

  fragment CtaBlockContainer on Contentstack_component__cta_block {
    id
    title
    text
    button_label
    url
    style
    image {
      url
    }
  }

  fragment RichText on Contentstack_component__rich_text {
    id
    content
  }

  fragment RelatedArticles on Contentstack_component__related_articles {
    id
    title
    links {
      ... on Contentstack_page_resource {
        id
        link: url
        title
        metadata {
          description
          image {
            url
          }
        }
        resource {
          ... on Contentstack_topic_resource {
            feature_image {
              url
            }
          }
        }
      }
      ... on Contentstack_page_product {
        id
        url
        title
        metadata {
          description
          image {
            url
          }
        }
      }
      ... on Contentstack_page_job_specific_programs {
        id
        url
        title
        metadata {
          description
          image {
            url
          }
        }
      }
      ... on Contentstack_page_industry {
        id
        url
        title
        metadata {
          description
          image {
            url
          }
        }
      }
      ... on Contentstack_page_flexible {
        id
        link: url
        title
        metadata {
          description
          image {
            url
          }
        }
      }
      ... on Contentstack_page_executive_team {
        id
        url
        title
        metadata {
          description
          image {
            url
          }
        }
      }
      ... on Contentstack_page_events {
        id
        url
        title
        metadata {
          description
          image {
            url
          }
        }
      }
      ... on Contentstack_page_client_story {
        id
        link: url
        title
        metadata {
          description
          image {
            url
          }
        }
      }
      ... on Contentstack_page_client_stories_overview {
        id
        url
        title
        metadata {
          description
          image {
            url
          }
        }
      }
      ... on Contentstack_page_business_info {
        id
        url
        title
        metadata {
          description
          image {
            url
          }
        }
      }
    }
  }
`
