import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'
import Wrapper from '../UI/Wrapper'

interface CtaBlockContainerProps {
  image: {
    url: string | null
  } | null
}

export const CtaBlockWrapper = styled(Wrapper)`
  padding: 20px 0px 42px;
`

export const CTABody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  padding: 35px 0;
  height: 100%;

  h3 {
    color: #0f034a;
    line-height: 20px;
    font-size: 17px;
    font-weight: 400;
  }

  p {
    font-size: 14px;
  }

  a {
    display: flex;
    border-radius: 8px;
    width: max-content;
    text-decoration: none;
  }

  button {
    border: none;
    border-radius: 8px;
    background: linear-gradient(90deg, #b570b0 0%, #45266b 100%);
    color: #ffffff;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 6px 20px;
    cursor: pointer;

    &:hover {
      background: #45266b;
    }
  }
`

export const CtaBlockImageContainer = styled.div<CtaBlockContainerProps>`
  display: flex;
  background-image: none;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.08);
  max-width: 680px;
  margin: 0 auto;
  padding: 20px;

  @media (min-width: ${rspTheme.breakpoints.values.md}px) {
    display: grid;
    grid-template-columns: 1fr 0.9fr 50px;
    background: url(${(props) => props.image?.url || ''}) 461px 4356px / 996px
      500px no-repeat;
    background-size: contain;
    background-position: center;
    min-height: 310px;
    border-radius: 0px;
    box-shadow: none;

    ${CTABody} {
      grid-column: 2;
    }
  }
`
