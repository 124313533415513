import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const RelatedArticleTitle = styled.div`
  font-size: 34px;
  color: ${rspTheme.palette.primary.main};
  margin-bottom: 45px;
`

export const RelatedArticleGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 39px;
  flex-wrap: wrap;

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    gap: 24px;
    flex-direction: column;
  }
`

export const RelatedArticleCard = styled(Link)<{
  $linksCount: number
}>`
  width: 100%;
  max-width: 354px;
  border-radius: 8px;
  background-color: ${rspTheme.palette.primary.contrastText};
  background-size: cover;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  flex-basis: 29%;
  flex-grow: ${({ $linksCount }) => ($linksCount > 2 ? 0 : 1)};

  &:hover {
    filter: brightness(0.98);
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    max-width: 100%;
    flex-basis: 100%;
  }
`

export const RelatedArticleImage = styled.div<{ image?: string }>`
  height: 163px;
  ${({ image }) =>
    image
      ? css`
          background-image: url(${image});
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        `
      : css`
          background-color: #e3e3e8;
        `}
`

export const RelatedArticleContainer = styled.div`
  padding: 30px 40px 51px 15px;
  > span {
    display: inline-block;
    font-size: 16px;
    color: ${rspTheme.palette.primary.main};
    line-height: 22px;
    margin-bottom: 9px;

    &::after {
      content: '▶';
      margin-left: 5px;
      display: inline-block;
      transform: scale(0.5, 0.75);
    }
  }

  > p {
    height: 110px;
    font-size: 16px;
    color: ${rspTheme.palette.text.primary};
    line-height: 22px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
      height: 66px;
      -webkit-line-clamp: 3;
    }
  }
`
