import parse from 'html-react-parser'
import React from 'react'

import Wrapper from '../UI/Wrapper'
import { RichTextContainer } from './styles'

export const RichText = ({ content, id }: Queries.RichTextFragment) => (
  <Wrapper id={id}>
    <RichTextContainer>{content ? parse(content) : null}</RichTextContainer>
  </Wrapper>
)
