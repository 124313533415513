import { Typography } from '@material-ui/core'
import parse from 'html-react-parser'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'
import { sanitizeUrlAnchor } from '../../utils/helpers'
import Wrapper from '../UI/Wrapper'
import { ContentNav } from './ContentNav'

const StyledWrapper = styled(Wrapper)`
  border-radius: 12px;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.08);
`

const StyledTopBar = styled.div`
  background-color: #a566a1;
  border-radius: 12px 12px 0px 0px;
  height: 19px;
  width: 100%;
`

const StyledContent = styled.div`
  padding: 26px 30px;
`

const StyledTitle = styled(Typography)`
  line-height: 55px;
`

const StyledList = styled.ol`
  column-count: 2;
  list-style-position: inside;
  list-style-type: decimal-leading-zero;
  padding: 0;

  li {
    margin-bottom: 23px;

    & ::marker {
      vertical-align: baseline;
    }
  }

  a {
    color: ${rspTheme.palette.secondary.dark};
    position: relative;
    text-decoration: none;
    vertical-align: middle;

    &:active,
    &:hover,
    &:focus {
      color: ${rspTheme.palette.secondary.dark};
    }

    &::after {
      bottom: 0;
      content: '🢓';
      line-height: 0.75;
      position: absolute;
      left: calc(100% + 8px);
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.sm}px) {
    column-count: unset;
  }
`

type PageQueryContentStack = NonNullable<Queries.PageQuery['contentstackPage']>

type Props = {
  components: NonNullable<PageQueryContentStack['components']>
}

export const TableOfContents = ({ components }: Props) => {
  const tableRef = useRef(null)
  const [showContentNav, setShowContentNav] = useState(false)

  const sectionsHeadings = components.reduce((acc, component) => {
    if (!component) {
      return acc
    }

    if (component.__typename === 'Contentstack_component__faq_accordion') {
      const faqHeading = sanitizeUrlAnchor(component.title)

      return [...acc, { id: faqHeading, heading: component.title }]
    }

    if (component.__typename === 'Contentstack_component__rich_text') {
      const contentHeading =
        component.content?.match(/(?<=<h1>)(.*)(?=<\/h1>)/g) ??
        component.content?.match(/(?<=<h2>)(.*)(?=<\/h2>)/g)

      return contentHeading
        ? [
            ...acc,
            {
              id: sanitizeUrlAnchor(contentHeading?.[0]),
              heading: contentHeading[0],
            },
          ]
        : acc
    }

    return acc
  }, [] as { id: string; heading: string }[])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // the number 60 comes from rspTheme.navHeightMobile
        if (entry.boundingClientRect.bottom < 60) {
          setShowContentNav(true)
        } else {
          setShowContentNav(false)
        }
      },
      { rootMargin: `-${rspTheme.navHeightMobile} 0px 0px` },
    )
    if (tableRef.current) {
      observer.observe(tableRef.current)
    }
    return () => {
      if (tableRef.current) {
        observer.unobserve(tableRef.current)
      }
    }
  }, [tableRef])

  return (
    <>
      <ContentNav sectionsHeadings={sectionsHeadings} show={showContentNav} />
      <StyledWrapper>
        <div ref={tableRef}>
          <StyledTopBar />
          <StyledContent>
            <StyledTitle color="primary" variant="h2">
              In This Guide
            </StyledTitle>
            <StyledList>
              {sectionsHeadings.map(({ id, heading }) => (
                <li key={id}>
                  <a href={`#${id}`}>{parse(heading)}</a>
                </li>
              ))}
            </StyledList>
          </StyledContent>
        </div>
      </StyledWrapper>
    </>
  )
}
