import React from 'react'
import styled from 'styled-components'

import Wrapper from '../UI/Wrapper'
import {
  RelatedArticleCard,
  RelatedArticleContainer,
  RelatedArticleGroup,
  RelatedArticleImage,
  RelatedArticleTitle,
} from './styles'

const StyledWrapper = styled(Wrapper)`
  margin: 78px auto 71px;
`

export const RelatedArticles = ({
  title,
  links,
  id,
}: Pick<Queries.RelatedArticlesFragment, 'title' | 'links' | 'id'>) => (
  <StyledWrapper id={id}>
    <RelatedArticleTitle>{title}</RelatedArticleTitle>
    <RelatedArticleGroup>
      {links?.map((link) =>
        link ? (
          <RelatedArticleCard
            $linksCount={links?.length ?? 0}
            key={link?.id}
            to={'link' in link ? link.link ?? '/' : link.url}
          >
            <RelatedArticleImage image={link?.resource?.[0]?.feature_image?.url ?? ''} />
            <RelatedArticleContainer>
              <span>{link?.title}</span>
              <p>{link?.metadata?.description}</p>
            </RelatedArticleContainer>
          </RelatedArticleCard>
        ) : null,
      )}
    </RelatedArticleGroup>
  </StyledWrapper>
)
