import React from 'react'

import Wrapper from '../UI/Wrapper'
import {
  RelatedLinkButtonContainer,
  RelatedLinkContainer,
  RelatedLinkImage,
  RelatedLinkImageContainer,
  Text,
  TextContainer,
  Title,
} from './styles'

export const RelatedLinkBox = ({
  title,
  text,
  button_label,
  url,
  image,
  style,
}: Omit<Queries.RelatedLinkBoxFragment, 'id'>) => (
  <Wrapper>
    <RelatedLinkContainer mode={style}>
      <TextContainer>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </TextContainer>
      <RelatedLinkButtonContainer>
        {url ? (
          <a href={url} rel="noopener noreferrer" target="_blank">
            <button type="button">{button_label}</button>
          </a>
        ) : null}
      </RelatedLinkButtonContainer>
      <RelatedLinkImageContainer>
        <RelatedLinkImage image={image?.url} />
      </RelatedLinkImageContainer>
    </RelatedLinkContainer>
  </Wrapper>
)
