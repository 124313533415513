import Typography from '@material-ui/core/Typography'
import Image from '@material-ui/icons/Image'
import React from 'react'
import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'
import Wrapper from '../UI/Wrapper'

const HeroWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 80px;
  margin-bottom: 48px;
`

const HeroImageWrapper = styled.div<{ image?: string }>`
  align-items: center;
  background: #ab7984 url(${({ image }) => image ?? ''}) center/cover no-repeat;
  display: flex;
  height: 460px;
  justify-content: center;
  position: relative;
  width: 100%;

  > svg {
    fill: #935c69;
    height: 250px;
    width: 250px;
    z-index: 1;
  }
`

const HeroText = styled(Typography)<{ heroColor?: string }>`
  color: ${({ heroColor }) => {
    switch (heroColor) {
      case 'dark':
        return rspTheme.palette.text.primary
      default:
        return rspTheme.palette.secondary.contrastText
    }
  }};
  position: absolute;
  max-width: 570px;
  z-index: 2;
`

const SubHeroText = styled(Wrapper)`
  font-size: 2.2rem;
  color: #232323;
  line-height: 3.1rem;
`

type Props = {
  color?: string | null
  image?: string | null
  subtext?: string | null
  text?: string | null
}

export const Hero = ({ color, image, subtext, text }: Props) => (
  <HeroWrapper>
    <HeroImageWrapper image={image ?? ''}>
      {!image ? <Image /> : null}
      {text ? (
        <HeroText align="center" heroColor={color ?? 'light'} variant="h1">
          {text}
        </HeroText>
      ) : null}
    </HeroImageWrapper>
    {subtext ? (
      <SubHeroText dangerouslySetInnerHTML={{ __html: subtext }} />
    ) : null}
  </HeroWrapper>
)
