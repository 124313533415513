import { Box, Typography } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import parse from 'html-react-parser'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'
import { debounce } from '../../utils/helpers'
import Wrapper from '../UI/Wrapper'

const StyledContainer = styled.div<{ isNavHeaderVisible: boolean }>`
  background-color: #ffffff;
  box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.08);
  position: fixed;
  top: 0;
  top: ${({ isNavHeaderVisible }) =>
    isNavHeaderVisible ? rspTheme.navHeightMobile : '0px'};
  transition: all 0.25s ease-in-out;
  width: 100%;
  z-index: 100;

  @media screen and (min-width: 1080px) {
    top: ${({ isNavHeaderVisible }) =>
      isNavHeaderVisible ? rspTheme.navHeight : '0px'};
  }
`

const StyledWrapper = styled(Wrapper)`
  height: 51px;
`

const StyledJumpToButton = styled(Box)`
  cursor: pointer;
  position: relative;
`

const StyledJumpToMenu = styled.div`
  background-color: #ffffff;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 250px;
  max-width: 90vw;
  min-width: 100px;
  overflow-y: scroll;
  padding: 16px;
  position: absolute;
  right: 0;
  width: max-content;

  a {
    color: ${rspTheme.palette.text.disabled};
    font-size: 14px;
    position: relative;
    text-decoration: none;
    vertical-align: middle;

    &:active,
    &:hover,
    &:focus {
      color: ${rspTheme.palette.text.disabled};
    }

    &.selected {
      color: ${rspTheme.palette.primary.main};

      &:active,
      &:hover,
      &:focus {
        color: ${rspTheme.palette.primary.main};
      }
    }

    &:hover {
      background-color: ${rspTheme.palette.primary.background};
    }
  }

  @media screen and (min-width: ${rspTheme.breakpoints.values.sm}) {
    max-width: 500px;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #cbced0;
    border-radius: 0px 0px 8px 0px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: black;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

const VISIBILITY_TRIGGER_TIMEOUT_MILISSECOND_CORRECTION = -1

type Props = {
  show: boolean
  sectionsHeadings: { id: string; heading: string }[]
}

export const ContentNav = ({ show, sectionsHeadings }: Props) => {
  const [showJumpToMenu, setShowJumpToMenu] = useState(false)
  const [prevScrollPosition, setPrevScrollPosition] = useState(0)
  const [isNavHeaderVisible, setIsNavHeaderVisible] = useState(true)

  useEffect(() => {
    const handleScroll = debounce(() => {
      const currentScrollPosition = window?.scrollY
      setIsNavHeaderVisible(
        prevScrollPosition > currentScrollPosition ||
          currentScrollPosition < 50,
      )
      setPrevScrollPosition(currentScrollPosition)
    }, 350 + VISIBILITY_TRIGGER_TIMEOUT_MILISSECOND_CORRECTION)

    window?.addEventListener('scroll', handleScroll)
    return () => {
      window?.removeEventListener('scroll', handleScroll)
    }
  }, [prevScrollPosition])

  if (!show) {
    return null
  }

  return (
    <StyledContainer isNavHeaderVisible={isNavHeaderVisible}>
      <StyledWrapper
        alignItems="center"
        display="flex"
        justifyContent="space-between"
      >
        <Typography variant="h4">In This Guide</Typography>
        <Box
          alignSelf="stretch"
          onMouseEnter={() => setShowJumpToMenu(true)}
          onMouseLeave={() => setShowJumpToMenu(false)}
          position="relative"
        >
          <StyledJumpToButton alignItems="center" display="flex" height="100%">
            <Typography variant="h6">Jump to</Typography>
            <KeyboardArrowDown fontSize="small" />
          </StyledJumpToButton>
          {showJumpToMenu ? (
            <StyledJumpToMenu>
              {sectionsHeadings.map(({ id, heading }) => (
                <a
                  className={location.hash === `#${id}` ? 'selected' : ''}
                  href={`#${id}`}
                  key={id}
                >
                  {parse(heading)}
                </a>
              ))}
            </StyledJumpToMenu>
          ) : null}
        </Box>
      </StyledWrapper>
    </StyledContainer>
  )
}
