import React from 'react'

import { booleanFilter } from '../../utils/helpers'

type Props = {
  questions: ReadonlyArray<{
    readonly answer: string | null
    readonly title: string
  } | null> | null
}

export const getFAQSchemaMarkupData = (
  questions:
    | {
        readonly answer: string | null
        readonly title: string
      }[]
    | undefined,
) => {
  if (!questions) {
    return null
  }

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: questions.map(({ answer, title }) => ({
      '@type': 'Question',
      name: title,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answer ?? '',
      },
    })),
  }
}

export const SchemaMarkupFAQ = ({ questions }: Props) => {
  const validQuestions = questions
    ?.filter(booleanFilter)
    .filter(({ answer }) => booleanFilter(answer))

  const faqMarkupData = getFAQSchemaMarkupData(validQuestions)

  return faqMarkupData ? (
    <script type="application/ld+json">{JSON.stringify(faqMarkupData)}</script>
  ) : null
}
