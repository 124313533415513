import parse from 'html-react-parser'
import React, { useState } from 'react'

import Wrapper from '../UI/Wrapper'
import {
  AccordionContent,
  AccordionItem,
  AccordionTitle,
  FaqAccordionContainer,
  Title,
} from './styles'

export const FaqAccordion = ({
  title,
  questions,
}: Pick<Queries.FaqAccordionFragment, 'title' | 'questions'>) => {
  const [openAccordion, setOpenAccordion] = useState<null | number>(null)

  return (
    <Wrapper>
      <FaqAccordionContainer>
        <Title>{title}</Title>

        {questions?.map((question, index) => (
          <AccordionItem key={question?.title}>
            <AccordionTitle
              onClick={() =>
                setOpenAccordion((prev) => (prev === index ? null : index))
              }
            >
              {question?.title}
            </AccordionTitle>
            {openAccordion === index && (
              <AccordionContent>
                {question?.answer ? parse(question.answer) : null}
              </AccordionContent>
            )}
          </AccordionItem>
        ))}
      </FaqAccordionContainer>
    </Wrapper>
  )
}
