import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const RichTextContainer = styled.div`
  padding-top: 42px;
  padding-bottom: 42px;

  > h1 {
    margin-bottom: 12px;
    font-size: 34px;
    line-height: 55px;
  }

  > h2 {
    margin-bottom: 17px;
    font-size: 24px;
    line-height: 30px;
  }

  > h1,
  > h2 {
    color: ${rspTheme.palette.primary.main};
  }

  > p {
    font-size: 16px;
    color: ${rspTheme.palette.text.primary};
    line-height: 28px;
    margin-bottom: 30px;

    u {
      text-decoration: underline solid rgb(35, 35, 35);
    }

    strong {
      font-weight: 400;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }

  > img {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  > ul {
    padding: 0;
    list-style-position: inside;

    li {
      line-height: 28px;
    }
  }
`
